import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "emi-calculator-fbc7a.firebaseapp.com",
    projectId: "emi-calculator-fbc7a",
    storageBucket: "emi-calculator-fbc7a.appspot.com",
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_API_ID,
    measurementId: process.env.REACT_APP_measurementId
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);