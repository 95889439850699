import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "zone.js"
import './App.css';
import Pricing from './Pricing/Pricing';
import { Layout } from 'antd';
import Affiliate from './Affiliate/Affiliate';

function App () {
  return (
    <BrowserRouter>
      <div className="App">
        <Layout style={{ minHeight: "100vh" }}>
          <Routes>
            <Route path='/' element={<Affiliate />} />
            <Route path='*' element={<Affiliate />} />
            <Route path='/:companyId' element={<Pricing />} />
          </Routes>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default App;
