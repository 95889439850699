import React, { useEffect, useState } from 'react';
import "./Pricing.css";
import { useParams } from 'react-router-dom';
import { firestore } from '../firebaseConfig';
import { getDoc, doc, onSnapshot, getDocs, collection, query, where, updateDoc, orderBy, addDoc, setDoc, deleteDoc } from "@firebase/firestore";
import Input from 'antd/es/input/Input';
import { Button, Select, message } from 'antd';

function Pricing () {
    const [ values, setValues ] = useState( {
        make: "",
        model: "",
        variant: ""
    } );
    const [ variantDetails, setVariantDetails ] = useState( {} );
    const [ make, setMake ] = useState( [] );
    const [ model, setModel ] = useState( [] );
    const [ variant, setVariant ] = useState( [] );
    const [ display, setDisplay ] = useState( [] );
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ access, setAccess ] = useState( "" );
    let { companyId } = useParams();

    async function onload () {
        let makeDocs = await getDocs( collection( firestore, "Make" ) );

        makeDocs.forEach( ( element ) => {
            make.push( element.id );
        } )
        setMake( [ ...make ] );
        document.querySelector( ".sending-loader" ).style.display = "none";
    }

    async function getAllDataWithMake ( value ) {
        setDisplay( [] );
        document.querySelector( ".sending-loader" ).style.display = "flex";
        let modelDocs = await getDocs( collection( firestore, "Make/" + value + "/Models" ) );
        setValues( { ...values, make: value } );

        let modelRaw = [];
        let displayRaw = [];
        modelDocs.forEach( async ( element2 ) => {
            let variantDocs = await getDocs( collection( firestore, "Make/" + value + "/Models/" + element2.id + "/Cities/Mumbai/Variants" ) );

            let querySnapshot1 = await getDoc( doc( firestore, "Make/" + value + "/Models", element2.id ) );

            variantDocs.forEach( async ( element ) => {
                let variantDoc = await getDoc( doc( firestore, "Make/" + value + "/Models/" + element2.id + "/Cities/Mumbai/Variants/", element.id ) );
                let vehicle_type_raw = querySnapshot1.data().vehicle_type;
                let specificationsObject = JSON.parse( variantDoc.data().specifications ?? "{}" );

                Object.keys( specificationsObject ).forEach( ( element ) => {
                    specificationsObject[ element ] = specificationsObject[ element ] === "" ? "N/A" : specificationsObject[ element ];
                } )

                let vehicle_premium_raw = 0;
                let variantDetailsRaw = {};
                if ( vehicle_type_raw === "Petrol" ) {
                    if ( parseFloat( querySnapshot1.data().thrust ) <= 75 ) {
                        vehicle_premium_raw = 2901;
                    } else if ( parseFloat( querySnapshot1.data().thrust ) > 75 && parseFloat( querySnapshot1.data().thrust ) <= 150 ) {
                        vehicle_premium_raw = 3851;
                    } else if ( parseFloat( querySnapshot1.data().thrust ) > 150 && parseFloat( querySnapshot1.data().thrust ) <= 350 ) {
                        vehicle_premium_raw = 7365;
                    } else {
                        vehicle_premium_raw = 15117;
                    }
                    variantDetailsRaw = {
                        "cc": querySnapshot1.data().thrust,
                        "vehicle_type": vehicle_type_raw,
                        "vehicle_premium": vehicle_premium_raw,
                        "insurance_discount": querySnapshot1.data().insurance_discount,
                        "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                        "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net"
                    }
                    setVariantDetails( {
                        "cc": querySnapshot1.data().thrust,
                        "vehicle_type": vehicle_type_raw,
                        "vehicle_premium": vehicle_premium_raw,
                        "insurance_discount": querySnapshot1.data().insurance_discount,
                        "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                        "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net"
                    } );
                } else {
                    if ( parseFloat( querySnapshot1.data().thrust ) <= 3 ) {
                        vehicle_premium_raw = 2466;
                    } else if ( parseFloat( querySnapshot1.data().thrust ) > 3 && parseFloat( querySnapshot1.data().thrust ) <= 7 ) {
                        vehicle_premium_raw = 3273;
                    } else if ( parseFloat( querySnapshot1.data().thrust ) > 7 && parseFloat( querySnapshot1.data().thrust ) <= 16 ) {
                        vehicle_premium_raw = 6260;
                    } else {
                        vehicle_premium_raw = 12849;
                    }
                    variantDetailsRaw = {
                        "cc": 0,
                        "vehicle_type": vehicle_type_raw,
                        "vehicle_premium": vehicle_premium_raw,
                        "insurance_discount": querySnapshot1.data().insurance_discount,
                        "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                        "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net"
                    }
                    setVariantDetails( {
                        "cc": 0,
                        "vehicle_type": vehicle_type_raw,
                        "vehicle_premium": vehicle_premium_raw,
                        "insurance_discount": querySnapshot1.data().insurance_discount,
                        "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                        "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net"
                    } );
                }

                let colorDocs = await getDocs( collection( firestore, "Make/" + value + "/Models/" + element2.id + "/Cities/Mumbai/Variants/" + element.id + "/Colors" ) );

                colorDocs.forEach( async ( element1 ) => {
                    let querySnapshot2 = await getDoc( doc( firestore, "Make/" + value + "/Models/" + element2.id + "/Cities/Mumbai/Variants/" + element.id + "/Colors", element1.id ) );
                    onSnapshot( doc( firestore, "company/" + companyId + "/Make/" + value + "/Models/" + element2.id + "/Variants/" + element.id + "/Colors", element1.id ), ( snapshot ) => {

                        let ex_showroom_price = parseInt( querySnapshot2.data().price );
                        let discount_percentage = parseFloat( variantDetailsRaw.insurance_discount );
                        let payout_percentage = parseFloat( variantDetailsRaw.payout_percentage );
                        let payout_on = variantDetailsRaw.payout_on;

                        let insurance = Math.round( ( ( ( ( parseInt( querySnapshot2.data().price ) * 0.95 ) * 0.01676 ) * ( ( 100 - 0 ) / 100 ) ) + parseFloat( variantDetailsRaw.vehicle_premium ) ) + ( ( ( ( ( parseInt( querySnapshot2.data().price ) * 0.95 ) * 0.01676 ) * ( ( 100 - 0 ) / 100 ) ) + parseFloat( variantDetailsRaw.vehicle_premium ) ) * 0.18 ) );
                        let final_insurance = insurance;

                        let mv_tax = 0;
                        if ( parseFloat( variantDetailsRaw.cc ) > 299 ) {
                            mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 12 / 100 ) );
                        } else if ( parseFloat( variantDetailsRaw.cc ) > 99 && parseFloat( variantDetailsRaw.cc ) <= 299 ) {
                            mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 11 / 100 ) );
                        } else if ( parseFloat( variantDetailsRaw.cc ) <= 99 && parseFloat( variantDetailsRaw.cc ) > 0 ) {
                            mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 10 / 100 ) );
                        } else if ( parseFloat( variantDetailsRaw.cc ) === 0 ) {
                            mv_tax = 0;
                        }
                        let insurance_discount_value = Math.round( ( ( ex_showroom_price * 0.95 ) * 0.01676 ) * ( discount_percentage / 100 ) );
                        let OD = Math.round( ( insurance_discount_value * 0.01676 ) * ( 100 - ( discount_percentage / 100 ) ) );
                        let TP = variantDetailsRaw.vehicle_premium;
                        let ZD = 0;
                        let PA = 0;
                        let Net = Math.round( OD + TP + ZD + PA );
                        let insurance_payout = 0;
                        if ( payout_on === "OD" ) {
                            insurance_payout = OD * ( payout_percentage / 100 );
                        } else {
                            insurance_payout = Net * ( payout_percentage / 100 );
                        }
                        let road_safety_tax = Math.round( mv_tax * ( 2 / 100 ) );
                        let new_registration = 300;
                        let smart_card_fee = 200;
                        let postal_fee = 50;
                        let agent_fee = 650;
                        let registration_price = mv_tax + road_safety_tax + new_registration + smart_card_fee + postal_fee + agent_fee;
                        let show_depo_charges = ( snapshot.data() !== undefined ? parseInt( snapshot.data().show_depo_charges ) : 0 );
                        let on_road_price = ex_showroom_price + registration_price + final_insurance;

                        let display_model_name = "";
                        let vendor_discount = 0;
                        let offer_discount = 0;
                        let premium = 0;
                        if ( snapshot.data() !== undefined ) {
                            vendor_discount = parseInt( snapshot.data().vendor_discount !== undefined ? snapshot.data().vendor_discount : 0 );
                            offer_discount = parseInt( snapshot.data().offer_discount !== undefined ? snapshot.data().offer_discount : 0 );
                            premium = parseInt( snapshot.data().premium !== undefined ? snapshot.data().premium : 0 );
                            let sku_discount = insurance_discount_value + insurance_payout + vendor_discount + ( show_depo_charges - premium ) - offer_discount;
                            let disc = parseInt( sku_discount / 100 ).toString();
                            display_model_name = value.substring( 0, 3 ).toUpperCase() + element.id.substring( 0, 3 ).toUpperCase() + "00".substring( disc.length ) + disc;
                        } else {
                            display_model_name = value.substring( 0, 3 ).toUpperCase() + element.id.substring( 0, 3 ).toUpperCase() + "00";
                        }

                        let object = {
                            make: value,
                            model: element2.id,
                            variant: element.id,
                            color: element1.id,
                            exShowroom: ex_showroom_price,
                            insurance: final_insurance,
                            rto: registration_price,
                            onRoadPrice: on_road_price,
                            premium: premium,
                            discount: vendor_discount,
                            buyingOnRoad: on_road_price + premium - vendor_discount,
                            insurance_discount_value: insurance_discount_value,
                            insurance_payout: insurance_payout,
                            net_discount: insurance_discount_value + insurance_payout + vendor_discount + ( show_depo_charges - premium ),
                            landing_cost: on_road_price + premium - vendor_discount,
                            show_depo_charges: show_depo_charges,
                            offer_discount: offer_discount,
                            netOnRoadPrice: parseFloat( on_road_price + show_depo_charges - offer_discount ),
                            sku: display_model_name,
                            standard_delivery: snapshot.data()?.standard_delivery ?? "",
                            standard_delivery_charge: snapshot.data()?.standard_delivery_charge ?? "",
                            express_delivery: snapshot.data()?.express_delivery ?? "",
                            express_delivery_charge: snapshot.data()?.express_delivery_charge ?? "",
                            zhatpat_delivery: snapshot.data()?.zhatpat_delivery ?? "",
                            zhatpat_delivery_charge: snapshot.data()?.zhatpat_delivery_charge ?? "",
                            last_updated: snapshot.data() !== undefined ? new Date( snapshot.data().last_updated.seconds * 1000 ).toLocaleString() : "Not Edited",
                        }
                        displayRaw.push( object );
                        setDisplay( [ ...displayRaw ] );
                    } );
                } )
            } )
            modelRaw.push( element2.id );
            setModel( [ ...modelRaw ] );
            setTimeout( () => {
                document.querySelector( ".sending-loader" ).style.display = "none";
            }, 1500 );
        } )
    }

    async function getAllData ( value ) {
        setDisplay( [] );
        document.querySelector( ".sending-loader" ).style.display = "flex";

        let querySnapshot1 = await getDoc( doc( firestore, "Make/" + values.make + "/Models", value ) );

        let variantDocs = await getDocs( collection( firestore, "Make/" + values.make + "/Models/" + value + "/Cities/Mumbai/Variants" ) );
        setValues( { ...values, model: value } );

        let variantsRaw = [];
        let displayRaw = [];
        variantDocs.forEach( async ( element ) => {
            let variantDoc = await getDoc( doc( firestore, "Make/" + values.make + "/Models/" + value + "/Cities/Mumbai/Variants/", element.id ) );
            let vehicle_type_raw = querySnapshot1.data().vehicle_type;
            let specificationsObject = JSON.parse( variantDoc.data().specifications ?? "{}" );
    
            Object.keys( specificationsObject ).forEach( ( element ) => {
                specificationsObject[ element ] = specificationsObject[ element ] === "" ? "N/A" : specificationsObject[ element ];
            } )
    
            let vehicle_premium_raw = 0;
            let variantDetailsRaw = {};
            if ( vehicle_type_raw === "Petrol" ) {
                if ( parseFloat( querySnapshot1.data().thrust ) <= 75 ) {
                    vehicle_premium_raw = 2901;
                } else if ( parseFloat( querySnapshot1.data().thrust ) > 75 && parseFloat( querySnapshot1.data().thrust ) <= 150 ) {
                    vehicle_premium_raw = 3851;
                } else if ( parseFloat( querySnapshot1.data().thrust ) > 150 && parseFloat( querySnapshot1.data().thrust ) <= 350 ) {
                    vehicle_premium_raw = 7365;
                } else {
                    vehicle_premium_raw = 15117;
                }
                variantDetailsRaw = {
                    "cc": querySnapshot1.data().thrust,
                    "vehicle_type": vehicle_type_raw,
                    "vehicle_premium": vehicle_premium_raw,
                    "insurance_discount": querySnapshot1.data().insurance_discount,
                    "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                    "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net",
                    "model_link": querySnapshot1.data().link,
                }
                setVariantDetails( {
                    "cc": querySnapshot1.data().thrust,
                    "vehicle_type": vehicle_type_raw,
                    "vehicle_premium": vehicle_premium_raw,
                    "insurance_discount": querySnapshot1.data().insurance_discount,
                    "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                    "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net",
                    "model_link": querySnapshot1.data().link,
                } );
            } else {
                if ( parseFloat( querySnapshot1.data().thrust ) <= 3 ) {
                    vehicle_premium_raw = 2466;
                } else if ( parseFloat( querySnapshot1.data().thrust ) > 3 && parseFloat( querySnapshot1.data().thrust ) <= 7 ) {
                    vehicle_premium_raw = 3273;
                } else if ( parseFloat( querySnapshot1.data().thrust ) > 7 && parseFloat( querySnapshot1.data().thrust ) <= 16 ) {
                    vehicle_premium_raw = 6260;
                } else {
                    vehicle_premium_raw = 12849;
                }
                variantDetailsRaw = {
                    "cc": 0,
                    "vehicle_type": vehicle_type_raw,
                    "vehicle_premium": vehicle_premium_raw,
                    "insurance_discount": querySnapshot1.data().insurance_discount,
                    "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                    "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net",
    
                }
                setVariantDetails( {
                    "cc": 0,
                    "vehicle_type": vehicle_type_raw,
                    "vehicle_premium": vehicle_premium_raw,
                    "insurance_discount": querySnapshot1.data().insurance_discount,
                    "payout_percentage": querySnapshot1.data().payout_percentage ? querySnapshot1.data().payout_percentage : "0.0",
                    "payout_on": querySnapshot1.data().payout_on ? querySnapshot1.data().payout_on : "Net",
    
                } );
            }
            let colorDocs = await getDocs( collection( firestore, "Make/" + values.make + "/Models/" + value + "/Cities/Mumbai/Variants/" + element.id + "/Colors" ) );

            colorDocs.forEach( async ( element1 ) => {
                let querySnapshot2 = await getDoc( doc( firestore, "Make/" + values.make + "/Models/" + value + "/Cities/Mumbai/Variants/" + element.id + "/Colors", element1.id ) );
                onSnapshot( doc( firestore, "company/" + companyId + "/Make/" + values.make + "/Models/" + value + "/Variants/" + element.id + "/Colors", element1.id ), ( snapshot ) => {

                    let ex_showroom_price = parseInt( querySnapshot2.data().price );
                    let discount_percentage = parseFloat( variantDetailsRaw.insurance_discount );
                    let payout_percentage = parseFloat( variantDetailsRaw.payout_percentage );
                    let payout_on = variantDetailsRaw.payout_on;

                    let insurance = Math.round( ( ( ( ( parseInt( querySnapshot2.data().price ) * 0.95 ) * 0.01676 ) * ( ( 100 - 0 ) / 100 ) ) + parseFloat( variantDetailsRaw.vehicle_premium ) ) + ( ( ( ( ( parseInt( querySnapshot2.data().price ) * 0.95 ) * 0.01676 ) * ( ( 100 - 0 ) / 100 ) ) + parseFloat( variantDetailsRaw.vehicle_premium ) ) * 0.18 ) );
                    let final_insurance = insurance;

                    let mv_tax = 0;
                    if ( parseFloat( variantDetailsRaw.cc ) > 299 ) {
                        mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 12 / 100 ) );
                    } else if ( parseFloat( variantDetailsRaw.cc ) > 99 && parseFloat( variantDetailsRaw.cc ) <= 299 ) {
                        mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 11 / 100 ) );
                    } else if ( parseFloat( variantDetailsRaw.cc ) <= 99 && parseFloat( variantDetailsRaw.cc ) > 0 ) {
                        mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 10 / 100 ) );
                    } else if ( parseFloat( variantDetailsRaw.cc ) === 0 ) {
                        mv_tax = 0;
                    }
                    let insurance_discount_value = Math.round( ( ( ex_showroom_price * 0.95 ) * 0.01676 ) * ( discount_percentage / 100 ) );
                    let OD = Math.round( ( insurance_discount_value * 0.01676 ) * ( 100 - ( discount_percentage / 100 ) ) );
                    let TP = variantDetailsRaw.vehicle_premium;
                    let ZD = 0;
                    let PA = 0;
                    let Net = Math.round( OD + TP + ZD + PA );
                    let insurance_payout = 0;
                    if ( payout_on === "OD" ) {
                        insurance_payout = OD * ( payout_percentage / 100 );
                    } else {
                        insurance_payout = Net * ( payout_percentage / 100 );
                    }
                    let road_safety_tax = Math.round( mv_tax * ( 2 / 100 ) );
                    let new_registration = 300;
                    let smart_card_fee = 200;
                    let postal_fee = 50;
                    let agent_fee = 650;
                    let registration_price = mv_tax + road_safety_tax + new_registration + smart_card_fee + postal_fee + agent_fee;
                    let show_depo_charges = ( snapshot.data() !== undefined ? parseInt( snapshot.data().show_depo_charges ) : 0 );
                    let on_road_price = ex_showroom_price + registration_price + final_insurance;

                    let display_model_name = "";
                    let vendor_discount = 0;
                    let offer_discount = 0;
                    let premium = 0;
                    if ( snapshot.data() !== undefined ) {
                        vendor_discount = parseInt( snapshot.data().vendor_discount !== undefined ? snapshot.data().vendor_discount : 0 );
                        offer_discount = parseInt( snapshot.data().offer_discount !== undefined ? snapshot.data().offer_discount : 0 );
                        premium = parseInt( snapshot.data().premium !== undefined ? snapshot.data().premium : 0 );
                        let sku_discount = insurance_discount_value + insurance_payout + vendor_discount + ( show_depo_charges - premium ) - offer_discount;
                        let disc = parseInt( sku_discount / 100 ).toString();
                        display_model_name = value.substring( 0, 3 ).toUpperCase() + element.id.substring( 0, 3 ).toUpperCase() + "00".substring( disc.length ) + disc;
                    } else {
                        display_model_name = value.substring( 0, 3 ).toUpperCase() + element.id.substring( 0, 3 ).toUpperCase() + "00";
                    }

                    let object = {
                        make: values.make,
                        model: value,
                        variant: element.id,
                        color: element1.id,
                        exShowroom: ex_showroom_price,
                        insurance: final_insurance,
                        rto: registration_price,
                        onRoadPrice: on_road_price,
                        premium: premium,
                        discount: vendor_discount,
                        buyingOnRoad: on_road_price + premium - vendor_discount,
                        insurance_discount_value: insurance_discount_value,
                        insurance_payout: insurance_payout,
                        net_discount: insurance_discount_value + insurance_payout + vendor_discount + ( show_depo_charges - premium ),
                        landing_cost: on_road_price + premium - vendor_discount,
                        show_depo_charges: show_depo_charges,
                        offer_discount: offer_discount,
                        netOnRoadPrice: parseFloat( on_road_price + show_depo_charges - offer_discount ),
                        sku: display_model_name,
                        standard_delivery: snapshot.data()?.standard_delivery ?? "",
                        standard_delivery_charge: snapshot.data()?.standard_delivery_charge ?? "",
                        express_delivery: snapshot.data()?.express_delivery ?? "",
                        express_delivery_charge: snapshot.data()?.express_delivery_charge ?? "",
                        zhatpat_delivery: snapshot.data()?.zhatpat_delivery ?? "",
                        zhatpat_delivery_charge: snapshot.data()?.zhatpat_delivery_charge ?? "",
                        last_updated: snapshot.data() !== undefined ? new Date( snapshot.data().last_updated.seconds * 1000 ).toLocaleString() : "Not Edited",
                    }
                    displayRaw.push( object );
                    setDisplay( [ ...displayRaw ] );
                    if ( displayRaw.length === colorDocs.docs.length ) {
                        document.querySelector( ".sending-loader" ).style.display = "none";
                    }
                } );
            } )
            variantsRaw.push( element.id );
            setVariant( [ ...variantsRaw ] );
        } )
        setTimeout( () => {
            document.querySelector( ".sending-loader" ).style.display = "none";
        }, 1500 );
    }

    async function getAllColors ( make, model, value ) {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        let colorDocs = await getDocs( collection( firestore, "Make/" + values.make + "/Models/" + values.model + "/Cities/Mumbai/Variants/" + value + "/Colors" ) );

        let displayRaw = [];
        colorDocs.forEach( async ( element ) => {
            let querySnapshot2 = await getDoc( doc( firestore, "Make/" + values.make + "/Models/" + values.model + "/Cities/Mumbai/Variants/" + value + "/Colors", element.id ) );
            onSnapshot( doc( firestore, "company/" + companyId + "/Make/" + values.make + "/Models/" + values.model + "/Variants/" + value + "/Colors", element.id ), ( snapshot ) => {

                let ex_showroom_price = parseInt( querySnapshot2.data().price );
                let discount_percentage = parseFloat( variantDetails.insurance_discount );
                let payout_percentage = parseFloat( variantDetails.payout_percentage );
                let payout_on = variantDetails.payout_on;

                let insurance = Math.round( ( ( ( ( parseInt( querySnapshot2.data().price ) * 0.95 ) * 0.01676 ) * ( ( 100 - 0 ) / 100 ) ) + parseFloat( variantDetails.vehicle_premium ) ) + ( ( ( ( ( parseInt( querySnapshot2.data().price ) * 0.95 ) * 0.01676 ) * ( ( 100 - 0 ) / 100 ) ) + parseFloat( variantDetails.vehicle_premium ) ) * 0.18 ) );
                let final_insurance = insurance;

                let mv_tax = 0;
                if ( parseFloat( variantDetails.cc ) > 299 ) {
                    mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 12 / 100 ) );
                } else if ( parseFloat( variantDetails.cc ) > 99 && parseFloat( variantDetails.cc ) <= 299 ) {
                    mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 11 / 100 ) );
                } else if ( parseFloat( variantDetails.cc ) <= 99 && parseFloat( variantDetails.cc ) > 0 ) {
                    mv_tax = Math.round( parseInt( querySnapshot2.data().price ) * ( 10 / 100 ) );
                } else if ( parseFloat( variantDetails.cc ) === 0 ) {
                    mv_tax = 0;
                }
                let insurance_discount_value = Math.round( ( ( ex_showroom_price * 0.95 ) * 0.01676 ) * ( discount_percentage / 100 ) );
                let OD = Math.round( ( insurance_discount_value * 0.01676 ) * ( 100 - ( discount_percentage / 100 ) ) );
                let TP = variantDetails.vehicle_premium;
                let ZD = 0;
                let PA = 0;
                let Net = Math.round( OD + TP + ZD + PA );
                let insurance_payout = 0;
                if ( payout_on === "OD" ) {
                    insurance_payout = OD * ( payout_percentage / 100 );
                } else {
                    insurance_payout = Net * ( payout_percentage / 100 );
                }
                let road_safety_tax = Math.round( mv_tax * ( 2 / 100 ) );
                let new_registration = 300;
                let smart_card_fee = 200;
                let postal_fee = 50;
                let agent_fee = 650;
                let registration_price = mv_tax + road_safety_tax + new_registration + smart_card_fee + postal_fee + agent_fee;
                let show_depo_charges = ( snapshot.data() !== undefined ? parseInt( snapshot.data().show_depo_charges ) : 0 );
                let on_road_price = ex_showroom_price + registration_price + final_insurance;

                let display_model_name = "";
                let vendor_discount = 0;
                let offer_discount = 0;
                let premium = 0;
                if ( snapshot.data() !== undefined ) {
                    vendor_discount = parseInt( snapshot.data().vendor_discount !== undefined ? snapshot.data().vendor_discount : 0 );
                    offer_discount = parseInt( snapshot.data().offer_discount !== undefined ? snapshot.data().offer_discount : 0 );
                    premium = parseInt( snapshot.data().premium !== undefined ? snapshot.data().premium : 0 );
                    let sku_discount = insurance_discount_value + insurance_payout + vendor_discount + ( show_depo_charges - premium ) - offer_discount;
                    let disc = parseInt( sku_discount / 100 ).toString();
                    display_model_name = value.substring( 0, 3 ).toUpperCase() + element.id.substring( 0, 3 ).toUpperCase() + "00".substring( disc.length ) + disc;
                } else {
                    display_model_name = value.substring( 0, 3 ).toUpperCase() + element.id.substring( 0, 3 ).toUpperCase() + "00";
                }

                let object = {
                    make: values.make,
                    model: values.model,
                    variant: value,
                    color: element.id,
                    exShowroom: ex_showroom_price,
                    insurance: final_insurance,
                    rto: registration_price,
                    onRoadPrice: on_road_price,
                    premium: premium,
                    discount: vendor_discount,
                    buyingOnRoad: on_road_price + premium - vendor_discount,
                    insurance_discount_value: insurance_discount_value,
                    insurance_payout: insurance_payout,
                    net_discount: insurance_discount_value + insurance_payout + vendor_discount + ( show_depo_charges - premium ),
                    landing_cost: on_road_price + premium - vendor_discount,
                    show_depo_charges: show_depo_charges,
                    offer_discount: offer_discount,
                    netOnRoadPrice: parseFloat( on_road_price + show_depo_charges - offer_discount ),
                    sku: display_model_name,
                    standard_delivery: snapshot.data()?.standard_delivery ?? "",
                    standard_delivery_charge: snapshot.data()?.standard_delivery_charge ?? "",
                    express_delivery: snapshot.data()?.express_delivery ?? "",
                    express_delivery_charge: snapshot.data()?.express_delivery_charge ?? "",
                    zhatpat_delivery: snapshot.data()?.zhatpat_delivery ?? "",
                    zhatpat_delivery_charge: snapshot.data()?.zhatpat_delivery_charge ?? "",
                    last_updated: snapshot.data() !== undefined ? new Date( snapshot.data().last_updated.seconds * 1000 ).toLocaleString() : "Not Edited",
                }
                console.log( on_road_price, show_depo_charges, final_insurance, offer_discount );
                displayRaw.push( object );
                setDisplay( [ ...displayRaw ] );
                if ( displayRaw.length === colorDocs.docs.length ) {
                    document.querySelector( ".sending-loader" ).style.display = "none";
                }
            } );
        } )
    }

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        onload();
    }, [] )

    return (
        <div className='pricing-container'>
            {contextHolder}
            <div className='sending-loader'>
                <span className="loader"></span>
                <span>Successfully Saved.</span>
            </div>
            <div className='pricing-inputs-container'>
                <div className='branch-form-elements'>
                    <div className='form-input-head'>
                        Select Make
                    </div>
                    <div className='form-input'>
                        <select name="makeSelect" className='makeSelect' onChange={async event => {
                            getAllDataWithMake( event.target.value );
                        }}>
                            <option value="">Select Make</option>
                            {make.map( ( element ) => (
                                <option value={element}>{element}</option>
                            ) )}
                        </select>
                    </div>
                </div>
                <div className='branch-form-elements'>
                    <div className='form-input-head'>
                        Select Model
                    </div>
                    <div className='form-input'>
                        <select name="modelSelect" className='modelSelect' disabled={values.make === "" ? true : false} onChange={async event => {
                            getAllData( event.target.value );
                        }}>
                            <option value="">Select Model</option>
                            {model.map( ( element ) => (
                                <option value={element}>{element}</option>
                            ) )}
                        </select>
                    </div>
                </div>
                <div className='branch-form-elements'>
                    <div className='form-input-head'>
                        Select Variant
                    </div>
                    <div className='form-input'>
                        <select name="variantSelect" className='variantSelect' disabled={values.model === "" ? true : false} onChange={event => {
                            getAllColors( values.make, values.model, event.target.value );
                        }}>
                            <option value="">Select Variant</option>
                            {variant.map( ( element ) => (
                                <option value={element}>{element}</option>
                            ) )}
                        </select>
                    </div>
                </div>
            </div>
            <div className='pricing-display-container'>
                <div className='pricing-display-container-heads'>
                    <div className='pricing-display-container-head'>
                        Make
                    </div>
                    <div className='pricing-display-container-head'>
                        Model
                    </div>
                    <div className='pricing-display-container-head'>
                        Variant
                    </div>
                    <div className='pricing-display-container-head'>
                        Color
                    </div>
                    <div className='pricing-display-container-head'>
                        Ex-Showroom
                    </div>
                    <div className='pricing-display-container-head'>
                        Insurance
                    </div>
                    <div className='pricing-display-container-head'>
                        Registration
                    </div>
                    <div className='pricing-display-container-head'>
                        On Road Price
                    </div>
                    <div className='pricing-display-container-head'>
                        Premium
                    </div>
                    <div className='pricing-display-container-head'>
                        Discount
                    </div>
                    <div className='pricing-display-container-head'>
                        Buying On Road
                    </div>
                    <div className='pricing-display-container-head'>
                        Insurance Discount
                    </div>
                    <div className='pricing-display-container-head'>
                        Insurance Payout
                    </div>
                    <div className='pricing-display-container-head'>
                        Net Discount
                    </div>
                    <div className='pricing-display-container-head'>
                        Net Landing On Road
                    </div>
                    <div className='pricing-display-container-head'>
                        Show Depo Charges
                    </div>
                    <div className='pricing-display-container-head'>
                        Offer Discount
                    </div>
                    <div className='pricing-display-container-head'>
                        Standard Delivery
                    </div>
                    <div className='pricing-display-container-head'>
                        Standard Delivery Charge
                    </div>
                    <div className='pricing-display-container-head'>
                        Express Delivery
                    </div>
                    <div className='pricing-display-container-head'>
                        Express Delivery Charge
                    </div>
                    <div className='pricing-display-container-head'>
                        Zhatpat Delivery
                    </div>
                    <div className='pricing-display-container-head'>
                        Zhatpat Delivery Charge
                    </div>
                    <div className='pricing-display-container-head'>
                        Net On Road Price
                    </div>
                    <div className='pricing-display-container-head'>
                        SKU
                    </div>
                    <div className='pricing-display-container-head'>
                        Last Modified
                    </div>
                    <div className='pricing-display-container-head'>
                        Save Changes
                    </div>
                </div>
                <div style={{ overflowY: "scroll", overflowX: "hidden", width: "260%" }}>
                    {display.map( ( element, index ) => (
                        <div className='pricing-display-container-content'>
                            <div className='pricing-display-container-head'>
                                {element.make}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.model}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.variant}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.color}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.exShowroom.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.insurance.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.rto.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.onRoadPrice.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.premium} onChange={event => {
                                    display[ index ][ "premium" ] = event.target.value;
                                    display[ index ][ "show_depo_charges" ] = parseInt( event.target.value ) + 3000;
                                    setDisplay( [ ...display ] );
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.discount} onChange={event => {
                                    display[ index ][ "discount" ] = event.target.value;
                                    setDisplay( [ ...display ] );
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.buyingOnRoad.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.insurance_discount_value.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.insurance_payout.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.net_discount.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.landing_cost.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.show_depo_charges.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.offer_discount} onChange={event => {
                                    display[ index ][ "offer_discount" ] = event.target.value;
                                    setDisplay( [ ...display ] );
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.standard_delivery} onChange={event => {
                                    display[ index ][ "standard_delivery" ] = event.target.value;
                                    setDisplay( [ ...display ] );
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.standard_delivery_charge} onChange={event => {
                                    display[ index ][ "standard_delivery_charge" ] = event.target.value;
                                    setDisplay( [ ...display ] );
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.express_delivery} onChange={event => {
                                    if ( parseInt( event.target.value ) < parseInt( element.standard_delivery ) ) {
                                        display[ index ][ "express_delivery" ] = event.target.value;
                                        setDisplay( [ ...display ] );
                                    } else if ( event.target.value === "" ) {
                                        display[ index ][ "express_delivery" ] = "";
                                        setDisplay( [ ...display ] );
                                    }
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.express_delivery_charge} onChange={event => {
                                    display[ index ][ "express_delivery_charge" ] = event.target.value;
                                    setDisplay( [ ...display ] );
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                {/* <Select
                                    defaultValue="lucy"
                                    style={{
                                        width: "100%",
                                    }}
                                    loading
                                    options={[
                                        {
                                            value: 'Yes',
                                            label: 'Yes',
                                        },
                                        {
                                            value: 'No',
                                            label: 'No',
                                        },
                                    ]}
                                /> */}
                                <Input type="text" value={element.zhatpat_delivery} onChange={event => {
                                    console.log( parseInt( element.express_delivery ), parseInt( event.target.value ) );
                                    if ( parseInt( event.target.value ) < parseInt( element.express_delivery ) ) {
                                        display[ index ][ "zhatpat_delivery" ] = event.target.value;
                                        setDisplay( [ ...display ] );
                                    } else if ( event.target.value === "" ) {
                                        display[ index ][ "zhatpat_delivery" ] = "";
                                        setDisplay( [ ...display ] );
                                    }
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                <Input type="text" value={element.zhatpat_delivery_charge} onChange={event => {
                                    display[ index ][ "zhatpat_delivery_charge" ] = event.target.value;
                                    setDisplay( [ ...display ] );
                                }} />
                            </div>
                            <div className='pricing-display-container-head'>
                                {element.netOnRoadPrice.toLocaleString( "en-IN", {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                } )}
                            </div>
                            <a href={element.model_link} target="_blank" rel="noreferrer" className='pricing-display-container-head'>
                                {element.sku}
                            </a>
                            <div className='pricing-display-container-head'>
                                {element.last_updated}
                            </div>
                            <div className='pricing-display-container-head'>
                                <Button onClick={async event => {
                                    document.querySelector( ".sending-loader" ).style.display = "flex";
                                    let count = 1;
                                    display.forEach( async ( element1 ) => {
                                        await setDoc( doc( firestore, "company/" + companyId + "/Make/" + element1.make + "/Models/" + element1.model + "/Variants/" + element1.variant + "/Colors", element1.color ), {
                                            last_updated: new Date(),
                                            premium: element1.premium !== "" ? element1.premium : 0,
                                            vendor_discount: element1.discount !== "" ? element1.discount : 0,
                                            standard_delivery: element1.standard_delivery !== "" ? element1.standard_delivery : "",
                                            standard_delivery_charge: element1.standard_delivery_charge !== "" ? element1.standard_delivery_charge : "",
                                            express_delivery: element1.express_delivery !== "" ? element1.express_delivery : '',
                                            express_delivery_charge: element1.express_delivery_charge !== "" ? element1.express_delivery_charge : '',
                                            zhatpat_delivery: element1.zhatpat_delivery !== "" ? element1.zhatpat_delivery : '',
                                            zhatpat_delivery_charge: element1.zhatpat_delivery_charge !== "" ? element1.zhatpat_delivery_charge : '',
                                            show_depo_charges: element1.show_depo_charges !== "" ? element1.show_depo_charges : 0,
                                            offer_discount: element1.offer_discount !== "" ? element1.offer_discount : 0,
                                        } )
                                        count += 1;
                                        if ( count === display.length ) {
                                            if ( values.variant !== "" ) {
                                                await getAllColors( element.make, element.model, element.variant );
                                            } else if ( values.model !== "" ) {
                                                await getAllData( element.model );
                                            } else if ( values.make !== "" ) {
                                                await getAllDataWithMake( element.make );
                                            }
                                            document.querySelector( ".sending-loader" ).style.display = "none";
                                        }
                                    } )
                                }}>Save</Button>
                            </div>
                        </div>
                    ) )}
                </div>
            </div>
        </div>
    )
}

export default Pricing